import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../../ALL Css/ALL Dashboard CSS/SubjectDetails.css';
import AdminHome from '../AdminHome';

const SubjectDetails = () => {
  const { suCode, boardCode, classCode } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  
  const boardName = ''; 
  const className = ''; 

  useEffect(() => {
    const fetchSubjects = async () => {
      setLoading(true);
      try {
        const response = await fetch(`/api/subjects`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            suCode: suCode,
            boardCode: boardCode,
            classCode: classCode,
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();

        const uniqueSubjects = data.reduce((acc, curr) => {
          const existing = acc.find(subject => subject.SubjectName === curr.SubjectName);
          if (existing) {
            existing.details.push(curr);
          } else {
            acc.push({ SubjectName: curr.SubjectName, details: [curr] });
          }
          return acc;
        }, []);
        
        setSubjects(uniqueSubjects);
      } catch (error) {
        console.error('Failed to fetch subjects:', error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubjects();
  }, [suCode, boardCode, classCode]);

  const handleSubjectClick = (subjectCode, subjectName) => {
    navigate(`/chapterdetails/${suCode}/${boardCode}/${classCode}/${subjectCode}`, 
      { state: {
        boardName: boardName, 
        className: className,
        subjectName: subjectName, 
      } });
  };

  return (
    <AdminHome>
      <div className="subject-details">
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}
        {subjects.map((subject) => (
          <div key={subject.SubjectName} className="subject-item">
            <button 
              onClick={() => handleSubjectClick(subject.details[0].SubjectCode, subject.SubjectName)}
              className="subject-button"
            >
              <span>{subject.SubjectName}</span>
            </button>
          </div>
        ))}
      </div>
    </AdminHome>
  );
};

export default SubjectDetails;
