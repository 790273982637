
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../ALL Css/ALL Dashboard CSS/MenuContent.css';
import AdminHome from '../AdminHome';

const BoardDetails = ({ suCode: propSuCode }) => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedBoards, setExpandedBoards] = useState({});
  const navigate = useNavigate();
  const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
  const suCode = storedUserDetails ? storedUserDetails.SUCode : null;
  
  useEffect(() => {
    const fetchSubjects = async () => {
      setLoading(true);
      try {
        const response = await fetch('/api/subjects', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ suCode }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setSubjects(data);
      } catch (error) {
        console.error('Failed to fetch subjects:', error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubjects();
  }, [suCode]);

  const handleBoardClick = (boardCode, boardName) => {
    navigate(`/classdetails/${suCode}/${boardCode}`, { state: { boardName } });
  };

  const toggleBoardExpansion = (boardName) => {
    setExpandedBoards((prevState) => ({
      ...prevState,
      [boardName]: !prevState[boardName]
    }));
  };

  const groupedSubjects = subjects.reduce((acc, subject) => {
    const { BoardCode, BoardName, ClassCode, ClassName, SubjectCode, SubjectName } = subject;

    if (!acc[BoardName]) {
      acc[BoardName] = { code: BoardCode, classes: {} };
    }
    if (!acc[BoardName].classes[ClassName]) {
      acc[BoardName].classes[ClassName] = { code: ClassCode, subjects: [] };
    }
    acc[BoardName].classes[ClassName].subjects.push({
      code: SubjectCode,
      name: SubjectName,
    });
    return acc;
  }, {});

  return (
    <AdminHome>
    <div className="edit-module">
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {Object.keys(groupedSubjects).map((boardName) => (
        <div key={groupedSubjects[boardName].code} className="board-row">
          <button
            onClick={() => {
              toggleBoardExpansion(boardName);
              handleBoardClick(groupedSubjects[boardName].code, boardName);
            }}
            className="board-button"
          >
            <span>{boardName}</span>
          </button>
          {expandedBoards[boardName] && (
            <div className="classes">
              {Object.keys(groupedSubjects[boardName].classes).map((className) => (
                <div key={groupedSubjects[boardName].classes[className].code} className="class-name">
                  <span>{className}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
    </AdminHome>
  );
};

export default BoardDetails;
