import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegistrationForm from '../Components/Registration & Login/RegistrationForm';
import LoginSelect from '../Components/Registration & Login/LoginSelect';
import FunScholarAdmin from '../Components/Registration & Login/LoginTypes/FunScholarAdmin';
import Home from '../Components/Dashboard/Home';
import ChapterDetails from '../Components/AdminPart/AdminDetails/ChapterDetails';
import AdminHome from '../Components/AdminPart/AdminHome';
import ClassDetails from '../Components/AdminPart/AdminDetails/ClassDetails';
import SubjectDetails from '../Components/AdminPart/AdminDetails/SubjectDetails';
import BoardDetails from '../Components/AdminPart/AdminDetails/BoardDetails';
// import PrivateRoute from './PrivateRoute';

const RoutesConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/registrationform" element={<RegistrationForm />} />
        <Route path="/home" element={<Home />} />
        <Route path="/loginselect" element={<LoginSelect />} />
        <Route path="/funscholaradmin" element={<FunScholarAdmin />} />
        <Route path="/boarddetails" element={<BoardDetails />} />
        <Route path="/classdetails/:suCode/:boardCode" element={<ClassDetails />} />
        <Route path="/subject-details/:suCode/:boardCode/:classCode" element={<SubjectDetails />} />
        <Route path="/chapterdetails/:suCode/:boardCode/:classCode/:subjectCode" element={<ChapterDetails />} />
        <Route path="/adminhome" element={<AdminHome />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesConfig;
