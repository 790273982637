import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate, useLocation } from 'react-router-dom';
import BoardDetails from './AdminDetails/BoardDetails';
import '../../ALL Css/ALL Dashboard CSS/AdminHome.css';

const AdminHome = ({ children }) => {
  const [menuData, setMenuData] = useState([]);
  const [expandedMenu, setExpandedMenu] = useState(null);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [isProfilePopupVisible, setIsProfilePopupVisible] = useState(false);
  const [selectedSubsubmenuContent, setSelectedSubsubmenuContent] = useState(null);
  const [LoadedComponent, setLoadedComponent] = useState(null);
  const [selectedMenuName, setSelectedMenuName] = useState('');
  const location = useLocation();
  const { boardName, className, subjectName } = location.state || {};
  const navigate = useNavigate();
  const profilePopupRef = useRef(null);
  const [currentBoardName, setCurrentBoardName] = useState(boardName || '');
  const [currentClassName, setCurrentClassName] = useState(className || '');
  const [currentSubjectName, setCurrentSubjectName] = useState(subjectName || '');

  const iconMapping = {
    "Dashboard": "bi bi-house",
    "User Management": "bi bi-person",
    "Student enrollment": "bi bi-file-earmark-person",
    "Batch Management": "bi bi-calendar",
    "Teacher": "bi bi-person-check",
    "LMS`": "bi bi-book",
    "Exam management portal": "bi bi-file-earmark-text",
    "Inventory Management": "bi bi-box",
    "Demo class management system": "bi bi-play-circle",
    "Communication": "bi bi-chat",
    "Doubt solving classes credit system": "bi bi-question-circle",
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
        const response = await fetch(`/menus?SUCode=${storedUserDetails.SUCode}&UserType=${storedUserDetails.UserType}&EMailID=${storedUserDetails.EmailID}`, {
          method: 'POST',
        });
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setMenuData(data);
      } catch (error) {
        setError('Error fetching menu: ' + error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchMenuData();
  }, []);

  useEffect(() => {
    if (location.state) {
      const { boardName, className, subjectName, fromClassDetails, selectedSubmenu } = location.state;
  
      if (fromClassDetails) {
        setCurrentBoardName(boardName || '');
        setCurrentClassName(className || '');
        setCurrentSubjectName(subjectName || '');
        setSelectedSubsubmenuContent(selectedSubmenu);
  
        // Set the component to BoardDetails
        setLoadedComponent(() => () => (
          <BoardDetails
            selectedSubsubmenuContent={selectedSubmenu}
            boardName={boardName || ''}
            className={className || ''}
            subjectName={subjectName || ''}
          />
        ));
      }
    }
  }, [location.state]);
  
  const toggleSubmenu = (menuCode) => {
    if (!isSidebarVisible) {
      setIsSidebarVisible(true);
    }
    setExpandedMenu((prev) => (prev === menuCode ? null : menuCode));
  };

  const handleSubmenuClick = (submenu) => {
    setSelectedSubsubmenuContent(submenu);
    setSelectedMenuName(submenu.MenuName);
    if (submenu.BoardName) {
      setCurrentBoardName(submenu.BoardName);
    }
    if (submenu.ClassName) {
      setCurrentClassName(submenu.ClassName);
    }
    if (submenu.SubjectName) {
      setCurrentSubjectName(submenu.SubjectName);
    }
    if (submenu.MenuName === 'Study Materials') {
      navigate('/boarddetails', { state: { selectedSubsubmenuContent: submenu, boardName: currentBoardName, className: currentClassName, subjectName: currentSubjectName } });
    } else {
      const Component = lazy(() => import(`./AdminDetails/${submenu.FrmName}.js`));
      setLoadedComponent(() => Component);
    }
  };

  const renderMenuItems = () => {
    const level1Menus = menuData.filter((item) => item.MenuLevel === 'Level1');
    return level1Menus.map((menu) => (
      <div key={menu.MenuCode}>
        <div
          onClick={() => toggleSubmenu(menu.MenuCode)}
          style={{
            fontFamily: '"Gill Sans", sans-serif',
            cursor: 'pointer',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
          }}
        >
          {isSidebarVisible && (
            <>
              <i className={iconMapping[menu.MenuName]} style={{ marginRight: '20px', fontSize: '20px' }}></i>
              {menu.MenuName}
            </>
          )}
          <span style={{ marginLeft: 'auto', transition: 'transform 0.3s', transform: expandedMenu === menu.MenuCode ? 'rotate(180deg)' : 'rotate(0deg)' }}>
            <i className="bi bi-chevron-down"></i>
          </span>
        </div>
        {expandedMenu === menu.MenuCode && renderSubmenuItems(menu.MenuCode)}
      </div>
    ));
  };

  const renderSubmenuItems = (parentMenuCode) => {
    const level2Submenus = menuData.filter((submenu) => submenu.ParentCode === parentMenuCode && submenu.MenuLevel === 'Level2');
    if (level2Submenus.length === 0) return null;

    return (
      <div className="submenu">
        {level2Submenus.map((submenu) => (
          <div key={submenu.MenuCode} className="submenu-item">
            <div
              onClick={() => handleSubmenuClick(submenu)}
              style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              {submenu.MenuName}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  const togglePopup = () => {
    setIsProfilePopupVisible((prev) => !prev);
    if (!isProfilePopupVisible) {
      const storedUserDetails = JSON.parse(sessionStorage.getItem('user'));
      if (storedUserDetails) {
        setUserDetails(storedUserDetails);
      }
    }
  };

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    setUserDetails(null);
    navigate('/loginselect');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profilePopupRef.current && !profilePopupRef.current.contains(event.target)) {
        setIsProfilePopupVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className='Container'>
      <nav className='Navbar'>
        <div className='Funscholar-Logo' onClick={toggleSidebar} style={{ cursor: 'pointer' }}>
        <img src="/fabicon.ico" alt="Funscholar Logo" />

        </div>
        <div className='Navbar-Heading'>
        {[selectedMenuName, currentBoardName, currentClassName, currentSubjectName]
            .filter(Boolean)
            .join(' > ')}

          </div>
        <div className="profile-container" onClick={togglePopup}>
          <div className="profile-icon">
            <i className="bi bi-person-circle" style={{ fontSize: '30px', marginRight: '50px' }}></i>
          </div>
        </div>
        {isProfilePopupVisible && userDetails && (
          <div className="profile-popup" ref={profilePopupRef}>
            <p><strong>UserType:</strong> {userDetails.UserType}</p>
            <p><strong>SUCode:</strong> {userDetails.SUCode}</p>
            <p><strong>User Name:</strong> {userDetails.UserName}</p>
            <p><strong>Mobile Number:</strong> {userDetails.Mobile}</p>
            <p><strong>Whatsapp:</strong> {userDetails.WhatsApp}</p>
            <p><strong>Email Id:</strong> {userDetails.EmailID}</p>
            <p><strong>Company Code:</strong> {userDetails.CompCode}</p>
            <p><strong>Company Name:</strong> {userDetails.CompName}</p>
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
      </nav>
      <div className="container-fluid">
        <div className="sidebar-container">
          {isSidebarVisible ? (
            <div className="sidebar">
              {renderMenuItems()}
            </div>
          ) : (
            <div className="sidebar-collapsed">
              {menuData.map(menu => (
                <div key={menu.MenuCode} className="collapsed-menu" onClick={() => toggleSubmenu(menu.MenuCode)}>
                  <i className={iconMapping[menu.MenuName]}></i>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="Content">
        <Suspense fallback={<div>Loading...</div>}>
    {LoadedComponent ? (
      <LoadedComponent
        selectedSubsubmenuContent={selectedSubsubmenuContent}
        boardName={currentBoardName}
        className={currentClassName}
        subjectName={currentSubjectName}
      />
    ) : (
      children
    )}
  </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
